import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function ProductQuality() {
  return (
    <div>
      <Container id="getstarted">
        <Row style={{ textAlign: 'center', padding: '130px 0 70px 0' }}>
          <Col md={4} className="top_space_10">
            <i className="fa-solid fa-leaf top_bottom_10_padding product_icon"></i>
            <h3 className="main_font top_bottom_10_padding">High Quality</h3>
            <p className="main_font gray-color top_bottom_10_padding">
              We are committed to making a high quality cloth Menstrual Pad to
              distribute to the community
            </p>
          </Col>

          <Col md={4} className="top_space_10">
            <i className="fa-solid fa-industry top_bottom_10_padding product_icon"></i>
            <h3 className="main_font top_bottom_10_padding">Locally Made</h3>
            <p className="main_font gray-color top_bottom_10_padding">
              We use locally available materials that are hygienic and
              breathable to ensure our products meet the highest standards.
            </p>
          </Col>

          <Col md={4} className="top_space_10">
            <i className="fa-brands fa-padlet top_bottom_10_padding product_icon"></i>
            <h3 className="main_font top_bottom_10_padding">Reusable Pads</h3>
            <p className="main_font gray-color top_bottom_10_padding">
              We proud in being able to produce and offer reusable sanitary pads
              to the community and the people we serve.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductQuality;
