import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function PortfolioHead() {
  return (
    <div>
      <Container
        className="text-center mt-5 main_font"
        style={{ paddingTop: '30px' }}
      >
        <Row className="justify-content-center top_space_20">
          <Col xs={12} sm={12} md={8} lg={8} xl={6}>
            <h5 className="primary-color main_font mb-3">Our Activities</h5>
            <h2>Our Recent Updates, Activities and Events </h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PortfolioHead;
