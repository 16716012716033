import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function ContactLanding() {
  return (
    <div className="contact_landing_container">
      <Container>
        <Row className="landing_row">
          <Col md={6}>
            <h1 className="landing_header">Contact Us</h1>
          </Col>
          <Col md={6} className="landing_nav">
            <p>Home // Contact Us</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactLanding;
