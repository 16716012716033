import React from 'react';
import { Col, Container, Image, Nav, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function AboutUs() {
  return (
    <div>
      <Container>
        <Row>
          <Col md={6}>
            <Image
              className="about_us_img"
              style={{ borderRadius: '10px' }}
              src="/images/pads3.jpg"
            />
          </Col>

          <Col md={6}>
            <h5 className="primary-color about_top_padding">We Are Ahadi</h5>
            <h1>The name Ahadi Means Promise</h1>
            <p className="gray-color">
              Our promise is to provide an affordable, hygienic Reusable
              feminine Pads for girls and women to use during their menstrual
              period. The Pads are a High Quality, long-lasting, Reliable & Cost
              effective as they can be Washed, Dried and Re-Used.
            </p>
            <p className="gray-color">
              With these Pads girls and women will engage in daily activities
              with dignity and comfort , this will eradicate the shame, stigma
              resulting in confidence and increased productivity at work and
              improved performance at school for there will be no absenteeism.
            </p>
            <LinkContainer to="/about-us">
              <Nav.Link>
                <button
                  className="primary-button-style"
                  style={{ marginLeft: '-15px' }}
                >
                  Read More
                </button>
              </Nav.Link>
            </LinkContainer>{' '}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;
