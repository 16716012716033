import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function VideoSection() {
  return (
    <div>
      <Container className="main_font">
        <Row className="text-center justify-content-center top_space_20">
          <Col xs={12} sm={12} md={8} lg={8} xl={6}>
            <h1>Goals & Achievements</h1>
            <p className="gray-color">
              Ahadi Pads have featured in a number of articles both locally and
              internations. We have also features in some tv and radio station
              programs.
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={6} style={{ paddingTop: '30px' }}>
            <video width="100%" height="100%" controls autoPlay>
              <source src="videos/tv.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Col>
          <Col md={6} style={{ paddingTop: '30px' }}>
            <h1>Goals We Have Accomplished So Far</h1>

            <p className="gray-color">
              We have managed to reduce waste by 1,800 kilos in that at the end
              of the year only 1,200 pads could be disposed of as opposed to
              36,000.00 disposable pads (reusable pads last more than one year
              with proper care.)
            </p>
            <p className="gray-color">
              We have produced more than 1000 packs in the past year with four
              pads in each. These we have sold and distributed in Nairobi and
              its environments. The 1000 packs have impacted 1000 girls in
              several schools, the most recent being 120 packs to Seeds of Hope
              Education Center in Kibera.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default VideoSection;
