import React from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';

function Testimonials() {
  return (
    <div>
      <Container>
        <Row>
          <Col></Col>
          <Col lg={8} md={10} xm={12}>
            <Carousel
              className="testimonials"
              touch={true}
              controls={false}
              pause="false"
            >
              <Carousel.Item interval={5000}>
                <div className="testimonialslide my_flex">
                  <div>
                    <img
                      src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png"
                      alt="testimonial1"
                      className="testimonial_img"
                    />
                    <div>
                      <h4>Head Teacher Mr. Paul</h4>
                    </div>
                    <div>
                      <p style={{ color: '#f0f0f0' }}>
                        Mt. Kenya Preparatory School
                      </p>
                    </div>

                    <div>
                      <p>
                        The Ahadi pads group were introduced to us by the Box
                        Girls organisation they visited us and has menstrual
                        health talk and donated pads to our 59 girls. Since that
                        time I leave noticed that all the girls attend and
                        participate in class and out doors activities too. Our
                        attendance has improved by 100% girls are safe and
                        fearless as one told me. Thank you for your training and
                        donations. Welcome back.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item interval={5000}>
                <div className="testimonialslide my_flex">
                  <div>
                    <img
                      src="https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png"
                      alt="testimonial1"
                      className="testimonial_img"
                    />
                    <div>
                      <h4>Susan Waithera</h4>
                    </div>
                    <div>
                      <p style={{ color: '#f0f0f0' }}>
                        Student Mt. Kenya Preparatory School
                      </p>
                    </div>

                    <div>
                      <p>
                        I am grateful to Ahadi for teaching about the menstrual
                        health teaching, I fully understand my menstrual circle
                        and I have pads to use when that time comes. I know I
                        will finish class 8 well.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Testimonials;
