import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function Clients() {
  return (
    <div>
      <Container>
        <Row className="text-center justify-content-center">
          <Col xs={12} sm={12} md={8} lg={8} xl={6}>
            <h1>Some Of Our Partners</h1>
            <p className="gray-color">
              Without following any specific order, here are some of the
              partners we are working with to help us achieve our goals
            </p>
          </Col>
        </Row>

        <Row className="main_client">
          <Col md={3} sm={6} xs={6} className="client_cards">
            <a
              href="https://www.kenyacic.org"
              target="_blank"
              rel="noreferrer"
              className="client_img"
            >
              <Card.Img variant="top" src="clients/6.png" />
            </a>
          </Col>

          <Col md={3} sm={6} xs={6} className="client_cards">
            <a
              href="https://www.coca-cola.co.ke"
              target="_blank"
              rel="noreferrer"
              className="client_img"
            >
              <Card.Img variant="top" src="clients/2.png" />
            </a>
          </Col>

          <Col md={3} sm={6} xs={6} className="client_cards">
            <a
              href="https://thepadproject.org"
              target="_blank"
              rel="noreferrer"
              className="client_img"
            >
              <Card.Img variant="top" src="clients/3.png" />
            </a>
          </Col>

          <Col md={3} sm={6} xs={6} className="client_cards">
            <a
              href="https://www.somoafrica.org"
              target="_blank"
              rel="noreferrer"
              className="client_img"
            >
              <Card.Img variant="top" src="clients/4.jpeg" />
            </a>
          </Col>

          <Col md={3} sm={6} xs={6} className="client_cards">
            <a
              href="https://dwibo.org"
              target="_blank"
              rel="noreferrer"
              className="client_img"
            >
              <Card.Img variant="top" src="clients/5.jpg" />
            </a>
          </Col>

          <Col md={3} sm={6} xs={6} className="client_cards">
            <a
              href="https://www.boxgirlskenya.com"
              target="_blank"
              rel="noreferrer"
              className="client_img"
            >
              <Card.Img variant="top" src="clients/1.png" />
            </a>
          </Col>

          <Col md={3} sm={6} xs={6} className="client_cards">
            <a
              href="https://www.boxgirlskenya.com"
              target="_blank"
              rel="noreferrer"
              className="client_img"
            >
              <Card.Img variant="top" src="clients/7.png" />
            </a>
          </Col>

          <Col md={3} sm={6} xs={6} className="client_cards">
            <a
              href="https://afrinov.org"
              target="_blank"
              rel="noreferrer"
              className="client_img"
            >
              <Card.Img variant="top" src="clients/9.png" />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Clients;
