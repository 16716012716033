import React from 'react';
import { Helmet } from 'react-helmet-async';
import AboutUs from '../components/AboutUs';
import Clients from '../components/Clients';
import HomeSlide from '../components/HomeSlide';
import PaypalDonate from '../components/PaypalDonate';
import Portfolio from '../components/Portfolio';
import PortfolioHead from '../components/PortfolioHead';
import ProductQuality from '../components/ProductQuality';
import VideoSection from '../components/VideoSection';

function HomeScreen() {
  return (
    <div className="main_font pt-5">
      <Helmet title="Ahadi Pads" />

      <HomeSlide />
      <ProductQuality />
      <div className="aboutus_homescreen">
        <AboutUs />
      </div>
      <div className="clients_homescreen">
        <Clients />
      </div>
      <div className="videosec_homescreen">
        <VideoSection />
      </div>
      <PortfolioHead />
      <Portfolio />

      <div className="donate_container">
        <PaypalDonate />
      </div>
    </div>
  );
}

export default HomeScreen;
