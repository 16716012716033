import React from 'react';
import { Helmet } from 'react-helmet-async';
import Achievement from '../components/Achievement';
import ContactAdressSection from '../components/ContactAdressSection';
import PaypalDonate from '../components/PaypalDonate';
import ProductDetails from '../components/ProductDetails';
import ServicesLanding from '../components/ServicesLanding';
import Testimonials from '../components/Testimonials';

function ServicesScreen() {
  return (
    <div>
      <Helmet title="Products" />

      <ServicesLanding />
      <ProductDetails />
      <div className="tetimonials_screen">
        <Testimonials />
      </div>
      <Achievement />
      <div className="contact_screen">
        <ContactAdressSection />
      </div>
      <div className="donate_container">
        <PaypalDonate />
      </div>
    </div>
  );
}

export default ServicesScreen;
