import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function Achievement() {
  return (
    <div>
      <Container fluid="true">
        <Row style={{ textAlign: 'center', padding: '70px 0 70px 0' }}>
          <Col md={3} className="top_space_10 achievement">
            <div className="pad-right">
              <i className="fa-solid fa-hand-holding-heart top_bottom_10_padding product_icon"></i>
            </div>
            <div>
              <h1 className="main_font">
                <strong>3K+</strong>
              </h1>
              <h6 className="main_font gray-color">// PEOPLE REACHED</h6>
            </div>
          </Col>

          <Col md={3} className="top_space_10 achievement">
            <div className="pad-right">
              <i className="fa-solid fa-filter-circle-dollar top_bottom_10_padding product_icon"></i>
            </div>
            <div>
              <h1 className="main_font">
                <strong>8K</strong>
              </h1>
              <h6 className="main_font gray-color">// DISTRIBUTED PADS</h6>
            </div>
          </Col>

          <Col md={3} className="top_space_10 achievement">
            <div className="pad-right">
              <i className="fa-solid fa-users top_bottom_10_padding product_icon"></i>
            </div>
            <div>
              <h1 className="main_font">
                <strong>34+</strong>
              </h1>
              <h6 className="main_font gray-color">// MENTORED PEOPLE</h6>
            </div>
          </Col>

          <Col md={3} className="top_space_10 achievement">
            <div className="pad-right">
              <i className="fa-solid fa-users top_bottom_10_padding product_icon"></i>
            </div>
            <div>
              <h1 className="main_font">
                <strong>180K tons</strong>
              </h1>
              <h6 className="main_font gray-color">// GARBAGE RESTRAINED</h6>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Achievement;
