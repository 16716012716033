import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function HomeSlide() {
  return (
    <div>
      <Carousel>
        <Carousel.Item pause="false" interval={4000}>
          <div className="homeslide my_flex">
            <div>
              <h1 className="my_head">Ahadi Reusable Pads</h1>

              <div>
                <p>
                  Maximizing Potentials, Fulfilling Destiny For Women & Girls
                </p>
              </div>
              <div style={{ paddingTop: '80px' }}>
                <a className="anchor_link" href="#getstarted">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <div className="homeslide2 my_flex">
            <div>
              <h1 className="my_head">Ahadi Reusable Pads</h1>

              <div>
                <p>
                  Maximizing Potentials, Fulfilling Destiny For Women & Girls
                </p>
              </div>
              <div style={{ paddingTop: '80px' }}>
                <a className="anchor_link" href="#getstarted">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <div className="homeslide3 my_flex">
            <div>
              <h1 className="my_head">Ahadi Reusable Pads</h1>

              <div>
                <p>
                  Maximizing Potentials, Fulfilling Destiny For Women & Girls
                </p>
              </div>
              <div style={{ paddingTop: '80px' }}>
                <a className="anchor_link" href="#getstarted">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default HomeSlide;
