import React from 'react';
import { Card, Col, Container, Image, ListGroup, Row } from 'react-bootstrap';

function ProductDetails() {
  return (
    <div>
      <Container>
        <Row style={{ padding: '80px 0' }}>
          <Col md={6}>
            <h5 className="primary-color">Ahadi Pads Products</h5>
            <h1>We Manufacture Sanitary Reusable Pads</h1>
            <p className="gray-color">
              Ahadi Pads prides in being able to manufacture cost effective
              reusable pads that increase women’s dignity because of increased
              hygiene levels. This has given young girls and women confidence
              and assurance that, they will not be embarrassed and stigmatized
              because they cannot afford protection during their periods.
            </p>

            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>High Quality Pads</ListGroup.Item>
                <ListGroup.Item>Locally Made With Ahadi Pads</ListGroup.Item>
                <ListGroup.Item>Reusable Pads</ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>

          <Col className="about_top_padding" md={6}>
            <Image className="production_img" src="/images/21.jpeg" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductDetails;
