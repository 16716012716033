import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function ContactHead() {
  return (
    <div>
      <Container
        className="text-center mt-5 mb-5 main_font"
        style={{ paddingTop: '30px' }}
      >
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={8} lg={8} xl={6}>
            <h5 className="primary-color main_font mb-3">Contact Us</h5>
            <h2 className="mb-4">We Want To Hear From You</h2>
            <p className="gray-color">
              Please fill out the form on this section to contact with us. Or
              call between 8:00 a.m. and 5:00 p.m. EAT, Monday through Friday
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactHead;
