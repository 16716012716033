import React from 'react';
import { Helmet } from 'react-helmet-async';
import AboutLand from '../components/AboutLand';
import Achievement from '../components/Achievement';
import Clients from '../components/Clients';
import MissionVission from '../components/MissionVission';
import Team from '../components/Team';

function AboutUsScreen() {
  return (
    <div className="main_font pt-3">
      <Helmet title="About Us" />
      <AboutLand />
      <div className="missionvission_aboutscreen">
        <MissionVission />
      </div>
      <div className="team_aboutscreen">
        <Team />
      </div>
      <div style={{ padding: '80px 0' }}>
        <Clients />
      </div>
      <div className="achieve_aboutscreen">
        <Achievement />
      </div>
    </div>
  );
}

export default AboutUsScreen;
