import React from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

function MissionVission() {
  return (
    <div>
      <Container>
        <Row>
          <Col md={4}>
            <Image className="mv_us_img" src="/images/pads7.jpg" />
          </Col>
          <Col md={8} className="mv_space_top">
            <Row>
              <Col>
                <p>
                  The name AHADI means PROMISE in Kiswahili. And it is our
                  promise that women and girls will fulfill their destiny by
                  maximizing their potential through education.
                </p>
                <p>
                  According to a report by the Ministry of Education and Care
                  International on keeping girls in school 2.7 million girls in
                  primary and secondary schools cannot afford pads and 1 out of
                  10 girls miss school and may drop out due to periods because
                  they cannot afford pads.
                  <strong>This is where Ahadi Pads comes in</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6} className="mv_space">
                <Card className="vission_card" style={{ height: '280px' }}>
                  <Card.Body>
                    <Card.Title>Vission</Card.Title>
                    <Card.Text>
                      To be a leading manufacturer of a cost effective menstrual
                      hygiene sanitary solution and giving women and girls high
                      quality pads, which will save them from away from harsh
                      economy.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} sm={6} className="mv_space">
                <Card className="mission_card" style={{ height: '280px' }}>
                  <Card.Body>
                    <Card.Title>Mission</Card.Title>
                    <Card.Text>
                      To manufacture cost effective reusable pads that will
                      increase women’s and girls’ self-esteem and dignity
                      because of increased comfort and hygiene levels
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MissionVission;
