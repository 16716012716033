import React from 'react';
import { Container } from 'react-bootstrap';

function AhadiMap() {
  return (
    <Container className="bottom_space">
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            width="100%"
            height="400"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Ahadi%20Pads&t=&z=17&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            style={{ borderRadius: '15px' }}
            title="Ahadi Pads"
          />
        </div>
      </div>
    </Container>
  );
}

export default AhadiMap;
