import React from 'react';
import { Helmet } from 'react-helmet-async';
import ContactHead from '../components/ContactHead';
import ContactAdressSection from '../components/ContactAdressSection';
import AhadiMap from '../components/AhadiMap';
import ContactLanding from '../components/ContactLanding';
import PaypalDonate from '../components/PaypalDonate';

function ContactUsScreen() {
  return (
    <div>
      <Helmet title="Contact Us" />
      <ContactLanding />
      <ContactHead />
      <ContactAdressSection />
      <AhadiMap />
      <div className="donate_container">
        <PaypalDonate />
      </div>
    </div>
  );
}

export default ContactUsScreen;
