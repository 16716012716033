import React from 'react';
import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';

function Gallery() {
  const items = [
    {
      itemId: 'sample-id',
      mediaUrl: 'images/25.jpeg',
      metaData: {
        type: 'image',
        height: 450,
        width: 450,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'sample-id',
      mediaUrl: 'images/39.jpeg',
      metaData: {
        type: 'image',
        height: 700,
        width: 700,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'sample-id',
      mediaUrl: 'images/44.jpeg',
      metaData: {
        type: 'image',
        height: 200,
        width: 200,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'sample-id',
      mediaUrl: 'images/47.jpeg',
      metaData: {
        type: 'image',
        height: 150,
        width: 350,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'sample-id',
      mediaUrl: 'images/36.jpeg',
      metaData: {
        type: 'image',
        height: 700,
        width: 700,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'differentItem',
      mediaUrl: 'images/25.jpeg',
      metaData: {
        type: 'image',
        height: 200,
        width: 120,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'sample-id',
      mediaUrl: 'images/30.jpeg',
      metaData: {
        type: 'image',
        height: 100,
        width: 350,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'sample-id',
      mediaUrl: 'images/19.jpeg',
      metaData: {
        type: 'image',
        height: 100,
        width: 350,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'sample-id',
      mediaUrl: 'images/12.jpeg',
      metaData: {
        type: 'image',
        height: 100,
        width: 350,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },

    {
      itemId: 'sample-id',
      mediaUrl: 'images/34.jpeg',
      metaData: {
        type: 'image',
        height: 100,
        width: 350,
        title: 'Ahadi Image',
        description: 'Ahadi Gallery Image',
        focalPoint: [0, 0],
      },
    },
  ];

  const options = {
    // galleryLayout: -1,
    galleryLayout: 'grid',
  };

  const container = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const eventsListener = (eventName, eventData) =>
    console.log({ eventName, eventData });

  const scrollingElement = window;

  return (
    <div>
      <ProGallery
        items={items}
        options={options}
        container={container}
        eventsListener={eventsListener}
        scrollingElement={scrollingElement}
      />
    </div>
  );
}

export default Gallery;
