import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './funcs/ScrollToTop';
import AboutUsScreen from './screens/AboutUsScreen';
import ContactUsScreen from './screens/ContactUsScreen';
import FooterScreen from './screens/FooterScreen';
import GalleryScreen from './screens/GalleryScreen';
import HomeScreen from './screens/HomeScreen';
import NotFound from './screens/NotFound';
import ServicesScreen from './screens/ServicesScreen';

function App() {
  return (
    <BrowserRouter>
      <div className="main_font">
        <header>
          <Navbar
            fixed="top"
            collapseOnSelect
            expand="lg"
            className="navbar_style"
            variant="light"
          >
            <Container>
              <LinkContainer to="/">
                <Navbar.Brand>
                  <img
                    src="/logo.png"
                    width="130"
                    height="45"
                    className="d-inline-block align-top"
                    alt="Ahadi Pads"
                  />
                </Navbar.Brand>
              </LinkContainer>

              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              {/* <span className="navbar-toggler-icon"></span> */}
              {/* </Navbar.Toggle> */}
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>

                <LinkContainer className="my_link_navi" to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my_link_navi" to="/about-us">
                  <Nav.Link>About Us</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my_link_navi" to="/products">
                  <Nav.Link>Products</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my_link_navi" to="/gallery">
                  <Nav.Link>Gallery</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my_link_navi" to="/contact-us">
                  <Nav.Link>Contact Us</Nav.Link>
                </LinkContainer>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>

        <main>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/about-us" element={<AboutUsScreen />} />
            <Route path="/products" element={<ServicesScreen />} />
            <Route path="/gallery" element={<GalleryScreen />} />
            <Route path="/contact-us" element={<ContactUsScreen />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </main>

        <footer>
          <FooterScreen />
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
