import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { LinkContainer } from 'react-router-bootstrap';

function NotFound() {
  return (
    <div>
      <Helmet title="404 Not Found" />

      <Container className="not-found">
        <div>
          <h1>404 Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>

          <LinkContainer to="/">
            <Nav.Link>
              <button
                className="primary-button-style"
                style={{ marginLeft: '-15px' }}
              >
                Go Home
              </button>
            </Nav.Link>
          </LinkContainer>
        </div>
      </Container>
    </div>
  );
}

export default NotFound;
