import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function Team() {
  return (
    <div>
      <Container>
        <Row className="text-center justify-content-center">
          <Col xs={12} sm={12} md={8} lg={8} xl={6}>
            <h1>The Team</h1>
            <p className="gray-color">
              We are privilage to have a team of young and dynamic people who
              are dedicated to providing the best products and services to our
              customers and the community.
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={4}>
            <Card style={{ margin: '30px 0' }} className="team_card">
              <Card.Img
                width="100%"
                height="350px"
                variant="top"
                className="team_img"
                src="/team/beth.jpg"
              />
              <Card.Body className="team_card_body">
                <Card.Title>
                  <h5 className="primary-color">Bethsheba Otuga</h5>
                </Card.Title>
                <Card.Text>
                  <p className="gray-color">Founding Director</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ margin: '30px 0' }} className="team_card">
              <Card.Img
                width="100%"
                height="350px"
                variant="top"
                className="team_img"
                src="/team/ann.jpg"
              />
              <Card.Body className="team_card_body">
                <Card.Title>
                  <h5 className="primary-color">Ann Mwangi</h5>
                </Card.Title>
                <Card.Text>
                  <p className="gray-color">Programs</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card style={{ margin: '30px 0' }} className="team_card">
              <Card.Img
                width="100%"
                height="350px"
                variant="top"
                className="team_img"
                src="/team/winnie.jpg"
              />
              <Card.Body className="team_card_body">
                <Card.Title>
                  <h5 className="primary-color">Winnie Ojwang</h5>
                </Card.Title>
                <Card.Text>
                  <p className="gray-color">Accountant</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Team;
