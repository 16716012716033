import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

function Portfolio() {
  return (
    <div>
      <Container className="pt-5 pb-5 main_font">
        <Row md={12}>
          <Col md={6} lg={4} xl={4} className="bottom_space_40">
            <Card>
              <Card.Img
                variant="top"
                src="/images/pads2.jpg"
                style={{ height: '250px' }}
              />
              <Card.Body>
                <Card.Title>Pads Distribution</Card.Title>
                <Card.Text>
                  We have distributed 6,000+ packs to schools and communities
                </Card.Text>
                <button className="primary-button-style" type="submit">
                  View More
                </button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} xl={4} className="bottom_space_40">
            <Card>
              <Card.Img
                variant="top"
                src="/images/pads4.jpg"
                style={{ height: '250px' }}
              />
              <Card.Body>
                <Card.Title>Awareness Creations</Card.Title>
                <Card.Text>
                  We have reached more communities in Burtabwa, Makueni,
                  Kiambio, Kariobangi...
                </Card.Text>
                <button className="primary-button-style" type="submit">
                  View More
                </button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} xl={4} className="bottom_space_40">
            <Card>
              <Card.Img
                variant="top"
                src="/images/pads5.jpg"
                style={{ height: '250px' }}
              />
              <Card.Body>
                <Card.Title>Pad Making Training</Card.Title>
                <Card.Text>
                  We have trained 34 people on pad marking so far.
                </Card.Text>
                <button className="primary-button-style" type="submit">
                  View More
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Portfolio;
