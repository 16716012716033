import React from 'react';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';

function ContactAdressSection() {
  return (
    <div>
      <Container className="mt-5 main_font">
        <Row className="bottom_space">
          {/* Col on left */}

          <Col md={5} className="mb-5">
            <div className="main_left_row_div">
              <div className="left_icon_div primary-color dim-red">
                <i className="fa-solid fa-location-dot"></i>
              </div>
              <div className="left_text_div">
                <h2 className="heading-h2-font">Address</h2>
                <p className="gray-color">Buruburu, Nairobi, Kenya</p>
              </div>
            </div>

            <div className="main_left_row_div">
              <div className="left_icon_div green-color dim-green">
                <i className="fa-solid fa-envelope"></i>
              </div>
              <div className="left_text_div">
                <h2 className="heading-h2-font">Email</h2>
                <p className="gray-color">info@ahadipads.org</p>
              </div>
            </div>

            <div className="main_left_row_div ">
              <div className="left_icon_div secondary-color dim-blue">
                <i className="fa-solid fa-phone"></i>
              </div>
              <div className="left_text_div">
                <h2 className="heading-h2-font">Phone</h2>
                <p className="gray-color">0723 309 229</p>
              </div>
            </div>
          </Col>

          {/* Col on right */}

          <Col md={7}>
            <div className="main_right_row_div">
              <Form>
                <Row>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Your Name"
                      style={{ color: 'gray' }}
                      className="mb-3"
                    >
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Your Name"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email Address"
                      style={{ color: 'gray' }}
                      className="mb-3"
                    >
                      <Form.Control
                        size="lg"
                        type="email"
                        placeholder="Email Address"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Your Phone"
                      style={{ color: 'gray' }}
                      className="mb-3"
                    >
                      <Form.Control
                        size="lg"
                        type="tel"
                        placeholder="Your Phone"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Subject"
                      style={{ color: 'gray' }}
                      className="mb-3"
                    >
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Subject"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Message"
                      style={{ color: 'gray' }}
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Leave your message here"
                        style={{ minHeight: '150px' }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-3">
                    <button className="primary-button-style" type="submit">
                      Send Message
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactAdressSection;
