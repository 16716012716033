import React from 'react';
import { Helmet } from 'react-helmet-async';
import Gallery from '../components/Gallery';
import GalleryLanding from '../components/GalleryLanding';

function GalleryScreen() {
  return (
    <div>
      <Helmet title="Gallery" />

      <GalleryLanding />
      <div style={{ padding: '30px 0' }}>
        <Gallery />
      </div>
    </div>
  );
}

export default GalleryScreen;
