import React from 'react';
import { Col, Container, Form, InputGroup, Nav, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

function FooterScreen() {
  const year = new Date().getFullYear();
  return (
    <div>
      <div className="main_footer" style={{ color: 'whiteSmoke' }}>
        <Container className="main_font  pt-3">
          <Row>
            <Col md={4}>
              <h1>Ahadi Pads</h1>
              <p>
                It is our promise that women and girls will fulfill their
                destiny by maximizing their potential through education
              </p>

              <div className="social_links">
                <a
                  href="https://www.facebook.com/AhadiReusablePads"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-facebook-f social_links_icon"></i>
                </a>

                <a
                  href="https://www.linkedin.com/company/ahadi-reusable-pads/?trk=public_profile_topcard-current-company&originalSubdomain=ke"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa-brands fa-linkedin social_links_icon"
                    aria-hidden="true"
                  ></i>
                </a>

                <a
                  href="https://www.instagram.com/ahadi_pads/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-instagram-square social_links_icon"></i>
                </a>

                <a
                  href="https://twitter.com/ahadipads"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-twitter social_links_icon"></i>
                </a>
              </div>
            </Col>
            <Col md={2} className="footer_small_screen">
              <h4>Navigation</h4>

              <div className="footer_nav_links">
                <LinkContainer className="my-link" to="/">
                  <Nav.Link>- Home</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my-link" to="/about-us">
                  <Nav.Link>- About Us</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my-link" to="/products">
                  <Nav.Link>- Services</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my-link" to="/gallery">
                  <Nav.Link>- Gallery</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my-link" to="/contact-us">
                  <Nav.Link>- Contact Us</Nav.Link>
                </LinkContainer>
              </div>
            </Col>
            <Col md={3} className="footer_small_screen">
              <h4>All Services</h4>

              <div className="footer_nav_links">
                <LinkContainer className="my-link" to="/">
                  <Nav.Link>- Pad Making</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my-link" to="/about-us">
                  <Nav.Link>- Women Mentorship</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my-link" to="/products">
                  <Nav.Link>- Charity</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my-link" to="/gallery">
                  <Nav.Link>- Girl Child Education</Nav.Link>
                </LinkContainer>

                <LinkContainer className="my-link" to="/contact-us">
                  <Nav.Link>- Environmental Conservation</Nav.Link>
                </LinkContainer>
              </div>
            </Col>
            <Col md={3} className="footer_small_screen">
              <h4>Newsletter</h4>
              <p>
                Subscribe to our newsletter to get the latest news and updates
                about our services, products and upcoming events.
              </p>

              <div className="footer_newsletter">
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                      <Form.Control
                        placeholder="Email"
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        type="email"
                      />
                    </InputGroup>
                    <button className="primary-button-style" type="submit">
                      Subscribe
                    </button>
                  </Form.Group>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bottom_footer">
        <Container className="main_font pt-3">
          <Row className="footer_low">
            <Col className="text-left">
              <p className="flex-nowrap">
                Ahadi Pads <span>&copy; {year}</span>
              </p>
            </Col>
            <Col className="text-end">
              <p className="flex-nowrap">
                Developed By{' '}
                <span>
                  <strong>
                    <a href="https://chajiocloud.com" target="blank">
                      Chajio Cloud
                    </a>{' '}
                  </strong>{' '}
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default FooterScreen;
