import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function PaypalDonate() {
  return (
    <div>
      <Container>
        <Row className="PayPalRow">
          <Col className="paypal_txt" sm={8}>
            <h3>Support Our Work By Donating through PayPal</h3>
            <p>
              You can also support Ahadi Reusable Pads via MPesa Till: 754627
            </p>
          </Col>
          <Col sm={4}>
            <a
              className="donate-button-style"
              href="https://paypal.com/login"
              target="_blank"
              rel="noreferrer"
            >
              Donate Now
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PaypalDonate;
